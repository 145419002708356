@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradientBackground {
  width: 100%;
  height: 25vh; /* Adjust to cover only the top 1/4 of the screen */
  background: linear-gradient(270deg, #84fab0, #233830);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
}

/* Additional class for the rest of the background */
.solidBackground {
  width: 100%;
  height: 75vh; /* Cover the remaining 3/4 of the screen */
  background-color: #233830;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Acorn";
  src: local("Acorn"), url("fonts/Acorn/acorn.ttf") format("truetype");
}

@font-face {
  font-family: "GT";
  src: local("GT"), url("fonts/GT/gt.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
